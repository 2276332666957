import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	counter: undefined,
};

const dashboardSlice = createSlice({
	name: 'dashboard',
	initialState,
	reducers: {
		addCounter: (state, action) => {
			state.counter = action.payload;
		},
	},
});

export const { addCounter } = dashboardSlice.actions;
export default dashboardSlice.reducer;
