import SetAuthToken from '../../helpers/setAuthToken';
import { apiSlice } from '../api/apiSlice';
import { userLoggedIn } from './authSlice';

export const authApi = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		login: builder.mutation({
			query: (data) => ({
				url: '/auth/login?with_additional_data=true',
				method: 'POST',
				body: data,
			}),

			async onQueryStarted(arg, { queryFulfilled, dispatch }) {
				try {
					const result = await queryFulfilled;

					dispatch(
						userLoggedIn({
							accessToken: result.data.access_token,
							permissions: result.data.permissions,
							user: result.data.user,
							colors: result.data.vehicle_colors,
							checkboxs: result.data.vehicle_checkbox_items,
							conditions: result.data.vehicle_condition_items,
							locations: result.data.locations,
						}),
					);

					SetAuthToken(result.data.access_token);
				} catch (err) {
					// do nothing
				}
			},
		}),
	}),
});

export const { useLoginMutation } = authApi;
