import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	accessToken: undefined,
	permissions: undefined,
	user: undefined,
	colors: undefined,
	checkboxs: undefined,
	conditions: undefined,
	locations: undefined,
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		userLoggedIn: (state, action) => {
			state.accessToken = action.payload.accessToken;
			state.permissions = action.payload.permissions;
			state.user = action.payload.user;
			state.colors = action.payload.colors;
			state.checkboxs = action.payload.checkboxs;
			state.conditions = action.payload.conditions;
			state.locations = action.payload.locations;
		},
		userLoggedOut: (state) => {
			state.accessToken = undefined;
			state.permissions = undefined;
			state.user = undefined;
			state.colors = undefined;
			state.checkboxs = undefined;
			state.conditions = undefined;
			state.locations = undefined;
		},
	},
});

export const { userLoggedIn, userLoggedOut } = authSlice.actions;
export default authSlice.reducer;
