import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	data: undefined,
};

const headerSearchSlice = createSlice({
	name: 'searchHeader',
	initialState,
	reducers: {
		setVehicleData: (state, action) => {
			state.data = action.payload.data;
		},
		clearVehicleData: (state) => {
			state.data = undefined;
		},
	},
});

export const { setVehicleData, clearVehicleData } = headerSearchSlice.actions;
export default headerSearchSlice.reducer;
