import Popovers from '../../../components/bootstrap/Popovers';
import Header, { HeaderRight } from '../../../layout/Header/Header';
const projectName = process.env.REACT_APP_PROJECT_NAME;
const Header404 = () => {
	return (
		<Header>
			<HeaderRight>
				<Popovers title={projectName}>
					<p>{projectName}</p>
				</Popovers>
				<code>{projectName}</code>
			</HeaderRight>
		</Header>
	);
};

export default Header404;
