import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { FLUSH, PAUSE, PERSIST, persistReducer, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { apiSlice, apiSliceFile } from '../features/api/apiSlice';
import authSliceReducer from '../features/auth/authSlice';
import cartSlice from '../features/cart/cartSlice';
import dashboardSlice from '../features/dashboard/dashboardSlice';
import headerSearchSlice from '../features/headerSearch/headerSearchSlice';

//store.js
const persistConfig = {
	key: 'auth',
	storage,
	whitelist: ['auth', 'carts', 'dashboard'],
};

const reducers = combineReducers({
	[apiSlice.reducerPath]: apiSlice.reducer,
	[apiSliceFile.reducerPath]: apiSliceFile.reducer,
	auth: authSliceReducer,
	searchHeader: headerSearchSlice,
	carts: cartSlice,
	dashboard: dashboardSlice,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
	reducer: persistedReducer,
	devTools: process.env.NODE_ENV !== 'production',
	middleware: (getDefaultMiddlewares) =>
		getDefaultMiddlewares({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}).concat(apiSlice.middleware),
});

export type RootState = ReturnType<typeof reducers>;
