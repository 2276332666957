import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Alert from '../../../components/bootstrap/Alert';
import Button from '../../../components/bootstrap/Button';
import OffCanvas, {
	OffCanvasBody,
	OffCanvasHeader,
	OffCanvasTitle,
} from '../../../components/bootstrap/OffCanvas';
import Popovers from '../../../components/bootstrap/Popovers';
import ThemeContext from '../../../contexts/themeContext';
import useDarkMode from '../../../hooks/useDarkMode';
import Header, { HeaderLeft, HeaderRight } from '../../../layout/Header/Header';
import Dropdown, {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
} from '../../../components/bootstrap/Dropdown';
import LANG, { getLangWithKey } from '../../../lang';
import { useTranslation } from 'react-i18next';

const projectName = process.env.REACT_APP_PROJECT_NAME;

const DefaultHeader = () => {
	// const { width } = useDeviceScreen();
	const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();
	const [offcanvasStatus, setOffcanvasStatus] = useState(false);
	const { i18n } = useTranslation();

	const cartData = useSelector((state) => state.carts);
	const { user } = useSelector((state) => state.auth);

	const navigate = useNavigate();

	const styledBtn = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
	};

	const _handleGoNotification = (param, id) => {
		setOffcanvasStatus(false);
		if (param === 'all') {
			navigate('notifications');
		} else {
			navigate(`notifications/${id}`);
		}
	};

	let notifications = [];

	const localData = localStorage.getItem('notifications');

	if (localData) {
		notifications = JSON.parse(localData);
	}

	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng);
		// showNotification(
		// 	<span className='d-flex align-items-center'>
		// 		<Icon icon={getLangWithKey(lng)?.icon} size='lg' className='me-1' />
		// 		<span>{`Language changed to ${getLangWithKey(lng)?.text}`}</span>
		// 	</span>,
		// 	'You updated the language of the site. (Only "Aside" was prepared as an example.)',
		// );
	};

	return (
		<Header>
			<HeaderLeft className='col-md-4'>{projectName}</HeaderLeft>
			<HeaderRight className='ms-auto'>
				<div className='row g-3 align-items-center'>
					{/* Dark Mode */}
					<div className='col-auto'>
						<Popovers trigger='hover' desc='Dark / Light mode'>
							<Button
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...styledBtn}
								icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
								onClick={() => setDarkModeStatus(!darkModeStatus)}
								aria-label='Toggle fullscreen'
								data-tour='dark-mode'
							/>
						</Popovers>
					</div>

					{/* Lang Selector */}
					<div className='col-auto'>
						<Dropdown>
							<DropdownToggle hasIcon={false}>
								<Button
									// eslint-disable-next-line react/jsx-props-no-spreading
									{...styledBtn}
									icon={getLangWithKey(i18n.language)?.icon}
									aria-label='Change language'
									data-tour='lang-selector'
								/>
							</DropdownToggle>
							<DropdownMenu isAlignmentEnd data-tour='lang-selector-menu'>
								{Object.keys(LANG).map((i) => (
									<DropdownItem key={LANG[i].lng}>
										<Button
											icon={LANG[i].icon}
											onClick={() => changeLanguage(LANG[i].lng)}>
											{LANG[i].text}
										</Button>
									</DropdownItem>
								))}
							</DropdownMenu>
						</Dropdown>
					</div>
					{/* Full Screen */}
					<div className='col-auto'>
						<Popovers trigger='hover' desc='Fullscreen'>
							<Button
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...styledBtn}
								icon={fullScreenStatus ? 'FullscreenExit' : 'Fullscreen'}
								onClick={() => setFullScreenStatus(!fullScreenStatus)}
								aria-label='Toggle dark mode'
							/>
						</Popovers>
					</div>

					{user && user?.role === 1 && (
						<div className='col-auto' style={{ position: 'relative' }}>
							<Button
								// eslint-disable-next-line react/jsx-props-no-spreading
								{...styledBtn}
								icon='ShoppingCart'
								aria-label='ShoppingCart'
								onClick={() => navigate('/admin-checkouts')}></Button>
							<span className='position-absolute top-15 start-85 translate-middle badge rounded-pill bg-danger'>
								{cartData.allVehicleIDs ? cartData.allVehicleIDs.length : 0}
							</span>
						</div>
					)}

					{/*	Notifications */}
					<div className='col-auto'>
						<Button
							// eslint-disable-next-line react/jsx-props-no-spreading
							{...styledBtn}
							icon='Notifications'
							onClick={() => setOffcanvasStatus(true)}
							aria-label='Notifications'
						/>
					</div>
					<OffCanvas
						id='notificationCanvas'
						titleId='offcanvasExampleLabel'
						placement='end'
						isOpen={offcanvasStatus}
						setOpen={setOffcanvasStatus}>
						<OffCanvasHeader setOpen={setOffcanvasStatus}>
							<OffCanvasTitle id='offcanvasExampleLabel'>
								Notifications
							</OffCanvasTitle>

							<Button
								color='primary'
								onClick={() => _handleGoNotification('all', null)}>
								All Notifications
							</Button>

							{/* <Link to='/notifications'>All Notifications</Link> */}
						</OffCanvasHeader>
						<OffCanvasBody>
							{notifications &&
								notifications.map((notifi) => {
									return (
										<Alert
											isLight
											color='primary'
											className='flex-column align-items-start'
											key={notifi.id}>
											<h6
												onClick={() =>
													_handleGoNotification('single', notifi.id)
												}
												className='cursor-pointer'>
												<u>{notifi?.subject}</u>
											</h6>
											<p>DATE: {notifi?.created_at}</p>
										</Alert>
									);
								})}
						</OffCanvasBody>
					</OffCanvas>
				</div>
			</HeaderRight>
		</Header>
	);
};

export default DefaultHeader;
