import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	checkouts: [],
	allVehicleIDs: [],
};

const cartSlice = createSlice({
	name: 'carts',
	initialState,
	reducers: {
		addToCarts: (state, action) => {
			state.checkouts = action.payload.data;
			state.allVehicleIDs = action.payload.allVehicleIDs;
		},
		vehicleIDChange: (state, action) => {
			state.allVehicleIDs = action.payload;
		},
		clearCarts: (state) => {
			state.checkouts = [];
			state.allVehicleIDs = [];
		},
	},
});

export const { addToCarts, vehicleIDChange, clearCarts } = cartSlice.actions;
export default cartSlice.reducer;
