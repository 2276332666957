import PropTypes from 'prop-types';
import { FC } from 'react';
import logo from '../assets/logos/logo.jpg';

const brandLogo = `${process.env.REACT_APP_BASE_URL}/images/logo.png`;

interface ILogoProps {
	width?: number;
	height?: number;
	brandImage?: boolean;
}
const Logo: FC<ILogoProps> = ({ width, height, brandImage = true }) => {
	return (
		<img
			src={brandImage ? brandLogo : logo}
			alt={`${process.env.REACT_APP_PROJECT_NAME}`}
			height={height}
			width={width}
		/>
	);
};
Logo.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
	brandImage: PropTypes.bool,
};
Logo.defaultProps = {
	width: 2155,
	height: 854,
	brandImage: false,
};

export default Logo;
